import * as React from 'react';
import  { useEffect } from "react";
 import { Route } from 'react-router';
import  Home  from './features/Home/Home';
import {Layout} from './features/Home/Layout'
import Login from './features/Login/Login';
import Request from './features/Request/Request'
import Report from './features/Report/Report'
import { useDispatch,useSelector } from "react-redux";
import { getUserDetails } from "./features/Authentication/authenticationSlice";
import { useHistory} from "react-router-dom";

import './custom.css'



export default () => {

        const dispatch = useDispatch();       
        const history = useHistory();

        const { user,isAuthorised } = useSelector(
                (state) => state.authentication
              );

        useEffect(() => {
                dispatch(getUserDetails({ method: "GET" }));
              }, [dispatch]);

              useEffect(() => {
                if (isAuthorised) 
                {
                      
                  if(!user.userEmail){
                    history.push("/Login");
                  }
                  //else{
                  //  history.push("/Login");
                  //}
                        
                
              }
              }, [user]);

          
            

              return(

        <div>
             <Layout></Layout>
          
  <Route exact path='/Request' component={Request} /> 
      {/*<Route exact path='/Home' component={Home} /> */}
      <Route exact path='/Report' component={Report} />  
  

   <Route exact path='/Login' component={Login} /> 
   <Route exact path='/' component={Login} /> 
    
    </div>)
        
        
                

        
               
    
};
