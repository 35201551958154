export const columnsHCO = [   
    {
        field: 'id',
        headerName: 'ID',
        width: 80,
        typeof: Number,   
    },
    {
        field: "bannergroupid",
        headerName:  "BANNER GROUP ID",
        width: 120,
    },
    {
        field: "bannergroupname",
        headerName:  "BANNER GROUP NAME",
        width: 120,
    },
    {
        field: "organisationidL3",
        headerName:  "ORGANISATION ID L3",
        width: 120,
    },
    {
        field: "organisationnameL3",
        headerName:  "ORGANISATION NAME L3",
        width: 120,
    },
    {
        field: "organisationidL2",
        headerName:  "ORGANISATION ID L2",
        width: 120,
    },
    {
        field: "organisationnameL2",
        headerName:  "ORGANISATION NAME L2",
        width: 120,
    },
    {
        field: "organisationidL1",
        headerName:  "ORGANISATION ID L1",
        width: 120,
    },
    {
        field: "organisationnameL1",
        headerName:  "ORGANISATION NAME L1",
        width: 120,
    },
    {
        field: "workplaceid",
        headerName:  "WORKPLACE ID",
        width: 150,
    },
    {
        field: "workplacename",
        headerName: "WORKPLACE NAME",       
        width: 180,      
    },
    {
        field: "workplacetype",
        headerName:  "WORKPLACE TYPE",       
        width: 180,       
    },
    {
        field: "workplacespecialty", 
        headerName:  "WORKPLACE SPECIALTY",
        width: 180,        
    },
    {
        field: "publicprivate", 
        headerName:  "PUBLIC PRIVATE",
        width: 120,        
    },
    {
        field: "emailaddress", 
        headerName:  "EMAIL ADDRESS",
        width: 180,        
    },
    {
        field: "addresS1", 
        headerName:  "ADDRESS1",
        width: 120,        
    },
    {
        field: "addresS2", 
        headerName: "ADDRESS2",
        width: 120,        
    },
    {
        field: "addresS3", 
        headerName: "ADDRESS3",
        width: 120,        
    },  
    {
        field: "suburb" ,
        headerName: "SUBURB",
        width: 180,      
    },
    {
        field: "state" ,
        headerName: "STATE" ,
        width: 100,      
    },
    {
        field: "postcode",
        headerName: "POST CODE",
        width: 250,       
    },
    {
        field: "mailingaddresS1",
        headerName: "MAILING ADDRESS1",
        width: 250,       
    },
    {
        field: "mailingaddresS2",
        headerName: "MAILING ADDRESS2",
        width: 250,       
    },
    {
        field: "mailingaddresS3",
        headerName: "MAILING ADDRESS3",
        width: 250,       
    },
    {
        field: "mailingsuburb",
        headerName: "MAILING SUBURB",
        width: 250,       
    },
    {
        field: "mailingstate",
        headerName: "MAILING STATE",
        width: 250,       
    },
    {
        field: "mailingpostcode",
        headerName: "MAILING POST CODE",
        width: 250,       
    },
    {
        field: "metrocountry",
        headerName: "METRO COUNTRY",
        width: 250,       
    }, 
    {
        field:  "brickcode" ,
        headerName:  "BRICK CODE",
        width: 180,        
    },
    {
        field:  "brickdesc" ,
        headerName:  "BRICK DESC",
        width: 180,        
    },
    {
        field: "phonenumber",
        headerName: "PHONE NUMBER",
        width: 150,        
    },
    {
        field: "faxnumber",
        headerName: "FAX NUMBER",
        width: 150,        
    },
    {
        field: "mailstatus",
        headerName: "MAIL STATUS",
        width: 150,        
    },
    {
        field: "faxstatus",
        headerName: "FAX STATUS",
        width: 150,        
    },
    {
        field: "phonestatus",
        headerName: "PHONE STATUS",
        width: 150,        
    },
    {
        field: "wkpemailstatus",
        headerName: "WKPE MAIL STATUS",
        width: 150,        
    },
    {
        field: 'pseudobannergroupid',
        headerName: 'PSEUDO BANNERGROUP ID',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL1',
        headerName: 'PSEUDO ORGANISATION ID L1',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL2',
        headerName: 'PSEUDO ORGANISATION ID L2',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL3',
        headerName: 'PSEUDO ORGANISATION ID L3',
        width: 180,          
    },
    {
        field: 'pseudoworkplaceid',
        headerName: 'PSEUDO WORKPLACE ID',
        width: 180,        
    },
    
    {
        field: 'workplacefreshness',
        headerName: 'WORKPLACE FRESHNESS',
        width: 180,        
    }

];