import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUserDetails = createAsyncThunk(
  "authentication/getUserDetails",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("Auth/Secure", reqOptions).then((r) => {
      console.log("Response:", r);
      if (r.status === 401) {
        dispatch(updateAuthorized( false ));
      } else {      
        dispatch(updateAuthorized(true));  
        return r.json();
      }
      
      });
  }
);

const initialState = {
  user: {},
  isAuthorised: false,
  shouldRedirect: true,
  response: [],
  isLoading: false,
  status: "",
  logoutStatus: null,
  isSuperAdminRole: false,
  isAdminRole: false,
  isEditorRole: false,
  isViewerRole: false,
  isAdminPrivileges: false,
  isFailed: false,
  isInactive: false,
  isUnauthorized: false,
};
export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {    

    updateAuthorized(state, { payload }) {
            
      state.isAuthorised = payload;
      //state.CountryCode = 'AU';
  }
  },
  extraReducers: {
    [getUserDetails.pending]: (state, action) => {
      // state.status = "loading";
      state.isLoading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      if (payload) {      
        console.log(payload);
        state.user = payload;      
          //state.isAuthorised = true;
      }
      state.status = "success";
      state.isLoading = false;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getUserDetails", action.error.message);
    }  
  },
});

export const {updateAuthorized} =  authenticationSlice.actions;
export default authenticationSlice.reducer;
