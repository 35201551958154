import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import RadioGroup from "@mui/material/RadioGroup";
import { ReactComponent as Logo } from "../../Assets/logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
//import axios from "axios";
import { Radio } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  getMATSOC,
  getIndividualStatus,
  getActivityStatus,
  getHCP_Type,
  GetHCPSpecialty,
  GetHCPPRIMARYAFFILIATION,
  GetHCPRole,
  GetHCPTendency,
  GetHCOType,
  GetHCOSector,
  GetHCOMetroCountry,
  GetHCOState,
  GetHCOSpeciality,
  request,
  report,
  updateparama,
} from "./RequestSlice";
import { updateAuthorized } from "../Authentication/authenticationSlice";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Theme, useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Request() {
  const loginState = useSelector((state) => state.login);
  //const laouotState = useSelector((state) => state.login);
  //const { user, authenticated } = loginState;
  // const { selectedCountry, selectedUserType } = laouotState;
  //const [Country, setCountry] = React.useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  // const MATSOCList = getMATSOC();
  const { user, isAuthorised } = useSelector((state) => state.authentication);

  const requestState = useSelector((state) => state.request);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [showProgressReport, setShowProgressReport] = React.useState(false);
  const [invalidMATSOC, setInvalidMATSOC] = React.useState(false);
  const {
    HCPRecords,
    RecordCount,
    MATSOC,
    IndividualStatus,
    ActivityStatus,
    HCP_Type,
    HCPSpecialty,
    HCPPRIMARYAFFILIATION,
    HCPRole,
    HCPTendency,
    HCOType,
    HCOSector,
    HCOState,
    HCOSpeciality,
    CountryCode,
    HCOMetroCountry,
    selectedUserType,
    isSessionValid,
  } = requestState;
  // console.log("Tets==>", MATSOC);
  //const [MATSOCoptions, setMATSOCOptions] = useState([]);
  //const [showWarning, setShowWarning] = useState(false);
  //const handleTabChange = (event, newValue) => {
  //    setValue(newValue);
  //};

  useEffect(() => {
    if (!isAuthorised) {
      history.push("/");
    }
  }, [isAuthorised]);

  if (!isSessionValid) {
    dispatch(updateAuthorized(false));
  }

  useEffect(() => {
    //if(!isAuthorised){
    //  history.push('/');
    //  }
    //dispatch(updateAuthorized(false));

    setMATSOCValue(["NONE"]);

    setShowProgress(true);

    dispatch(getIndividualStatus({ country: CountryCode, method: "GET" }));
    dispatch(getActivityStatus({ country: CountryCode, method: "GET" }));
    dispatch(getHCP_Type({ country: CountryCode, method: "GET" }));
    dispatch(GetHCPSpecialty({ country: CountryCode, method: "GET" }));
    dispatch(GetHCPPRIMARYAFFILIATION({ country: CountryCode, method: "GET" }));
    dispatch(GetHCPRole({ country: CountryCode, method: "GET" }));
    dispatch(GetHCPTendency({ country: CountryCode, method: "GET" }));
    dispatch(GetHCOType({ country: CountryCode, method: "GET" }));
    dispatch(GetHCOSector({ country: CountryCode, method: "GET" }));
    if (CountryCode == "AU") {
      dispatch(GetHCOMetroCountry({ country: CountryCode, method: "GET" }));
    }
    dispatch(GetHCOState({ country: CountryCode, method: "GET" }));
    dispatch(GetHCOSpeciality({ country: CountryCode, method: "GET" })).then(
      () => {
        setShowProgress(false);
      }
    );

    // dispatch(GetHCOType({ method: "GET" }));
    // dispatch(GetHCOSector({ method: "GET" }));
    //// dispatch(GetHCOMetroCountry({ method: "GET" }));
    // dispatch(GetHCOState({ method: "GET" }));
  }, [CountryCode]);

  useEffect(() => {
    if (user.userId) {
      if (user.matsoc != null) {
        setMATSOCValue([user.matsoc]);
      }
      setShowProgress(true);
      dispatch(
        getMATSOC({ country: CountryCode, matsoc: user.matsoc, method: "GET" })
      ).then(() => {
        setShowProgress(false);
      });
    }
  }, [CountryCode, user]);

  useEffect(() => {
    //if(MATSOC.length ==0){
    //  setMATSOCValue("NONE");
    //  //alert(MATSOC);
    //  //setMATSOCValue(["NONE"]);

    //}

    //if(MATSOC.length ==1){
    //  setMATSOCValue(MATSOC[0]);
    //  //setMATSOCValue(["NONE"]);

    //}

    if (MATSOC.length == 0) {
      setMATSOCValue(["NONE"]);
    }
  }, [MATSOC]);

  useEffect(() => {
    setMATSOCValue(["NONE"]);
    //if(MATSOC.length ==1){
    //  setMATSOCValue(MATSOC[0]);
    //  //setMATSOCValue(["NONE"]);

    //}
    //else{
    //  setMATSOCValue("NONE");
    //}
    setIndividualStatusValue("3 - Valid");
    setActivityStatusValue("3 - Valid");
    setHCPTypeValue("All");
    setPrimaryAffilValue("All");
    setHCPRole1Value("All");
    setHCPRole2Value("All");
    setHCOSpecialtyValue("All");
    setHCOSectorValue("All");
    setMetroCountryValue("All");
    setHCOStateValue("All");
    setHCOStatusValue("3");
    setMailStatusValue("All");
    setCallStatusValue("All");
    setFaxStatusValue("All");
    setEmailStatusValue("All");

    setSpecialty1Value(["All"]);
    setSpecialty2Value(["All"]);
    setSpecialty3Value(["All"]);
    setHCPTendancyValue(["All"]);
    setHCOTypeValue(["All"]);
  }, [selectedUserType]);

  const theme = useTheme();

  const [IndividualStatusValue, setIndividualStatusValue] =
    React.useState("3 - Valid");
  const [ActivityStatusValue, setActivityStatusValue] =
    React.useState("3 - Valid");
  const [HCPTypeValue, setHCPTypeValue] = React.useState("All");
  const [PrimaryAffilValue, setPrimaryAffilValue] = React.useState("All");
  const [HCPRole1Value, setHCPRole1Value] = React.useState("All");
  const [HCPRole2Value, setHCPRole2Value] = React.useState("All");
  const [HCOSpecialtyValue, setHCOSpecialtyValue] = React.useState("All");
  const [HCOSectorValue, setHCOSectorValue] = React.useState("All");
  const [MetroCountryValue, setMetroCountryValue] = React.useState("All");
  const [HCOStateValue, setHCOStateValue] = React.useState("All");
  const [HCOStatusValue, setHCOStatusValue] = React.useState("3");
  const [MailStatusValue, setMailStatusValue] = React.useState("All");
  const [CallStatusValue, setCallStatusValue] = React.useState("All");
  const [FaxStatusValue, setFaxStatusValue] = React.useState("All");
  const [EmailStatusValue, setEmailStatusValue] = React.useState("All");

  //const [MATSOCValue, setMATSOCValue] = React.useState("NONE");
  const [MATSOCValue, setMATSOCValue] = React.useState([]);
  const [specialty1Value, setSpecialty1Value] = React.useState(["All"]);
  const [specialty2Value, setSpecialty2Value] = React.useState(["All"]);
  const [specialty3Value, setSpecialty3Value] = React.useState(["All"]);
  const [HCPTendancyValue, setHCPTendancyValue] = React.useState(["All"]);
  const [HCOTypeValue, setHCOTypeValue] = React.useState(["All"]);

  const handleChangeIndividualStatus = (event) => {
    setIndividualStatusValue(event.target.value);
  };

  const handleChangeActivityStatus = (event) => {
    setActivityStatusValue(event.target.value);
  };

  const handleChangeHCPType = (event) => {
    setHCPTypeValue(event.target.value);
  };

  const handleChangePrimaryAffil = (event) => {
    setPrimaryAffilValue(event.target.value);
  };

  const handleChangeHCPRole1 = (event) => {
    setHCPRole1Value(event.target.value);
  };

  const handleChangeHCPRole2 = (event) => {
    setHCPRole2Value(event.target.value);
  };

  const handleChangeHCOSpecialty = (event) => {
    setHCOSpecialtyValue(event.target.value);
  };

  const handleChangeHCOSector = (event) => {
    setHCOSectorValue(event.target.value);
  };

  const handleChangeMetroCountry = (event) => {
    setMetroCountryValue(event.target.value);
  };

  const handleChangeHCOState = (event) => {
    setHCOStateValue(event.target.value);
  };

  const handleChangeHCOStatus = (event) => {
    setHCOStatusValue(event.target.value);
  };

  const handleChangeMailStatus = (event) => {
    setMailStatusValue(event.target.value);
  };

  const handleChangeCallStatus = (event) => {
    setCallStatusValue(event.target.value);
  };

  const handleChangeFaxStatus = (event) => {
    setFaxStatusValue(event.target.value);
  };

  const handleChangeEmailStatus = (event) => {
    setEmailStatusValue(event.target.value);
  };

  //const handleChangeMatsoc = (event) => {
  //  setMATSOCValue(event.target.value);
  //};

  const handleChangeMatsoc = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length > 1 && value.includes("NONE")) {
      value.splice(value.indexOf("NONE"), 1);
    }
    if (!(user.matsoc != "null" && value.includes(user.matsoc))) {
      setMATSOCValue(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleChangeSpecialty1 = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length > 1 && value.includes("All")) {
      value.splice(value.indexOf("All"), 1);
    }
    setSpecialty1Value(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //alert(personName);
  };

  const handleChangeSpecialty2 = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes("All")) {
      value.splice(value.indexOf("All"), 1);
    }
    setSpecialty2Value(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //alert(personName);
  };

  const handleChangeSpecialty3 = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes("All")) {
      value.splice(value.indexOf("All"), 1);
    }
    setSpecialty3Value(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //alert(personName);
  };

  const handleChangeHCPTendency = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes("All")) {
      value.splice(value.indexOf("All"), 1);
    }
    setHCPTendancyValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //alert(personName);
  };

  const handleChangeHCOType = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes("All")) {
      value.splice(value.indexOf("All"), 1);
    }
    setHCOTypeValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //alert(personName);
  };

  //const handleChange = (event) => {
  //    debugger;
  //   // setCountry(event.target.value);
  //    //CountryCode = event.target.value;
  //};
  const handleSubmit = (event) => {
    event.preventDefault();
    if (MATSOC.length <= 1 && MATSOCValue == "NONE") {
      setInvalidMATSOC(true);
      setTimeout(() => {
        setInvalidMATSOC(false);
      }, 10000);
      return false;
    }
    const data = new FormData(event.currentTarget);
    // setLoading(true);
    setShowProgressReport(true);
    let body =
      selectedUserType == "HCP"
        ? {
            MATSOC: MATSOCValue, //.split("-")[0],//data.get("MATSOC").split("-")[0],
            HCP_IND_STATUS: IndividualStatusValue.split("-")[0], //data.get("IndividualStatus").replace(/ /g, "").split("-")[0],
            HCP_ACT_STATUS: ActivityStatusValue.split("-")[0], // data.get("ActivityStatus").replace(/ /g, "").split("-")[0],
            HCP_TYPE: HCPTypeValue.split("-")[0], //data.get("HCP_Type").replace(/ /g, "").split("-")[0],
            HCP_SP_type: data.get("radio-buttons-group"),
            HCP_SP1: specialty1Value, //data.get("HCPSpeciality1").replace(/ /g, "").split("-")[0],
            HCP_SP2: specialty2Value, //data.get("HCPSpeciality2").replace(/ /g, "").split("-")[0],
            HCP_SP3: specialty3Value, //data.get("HCPSpeciality3").split("-")[0],
            HCP_TIH1: HCPRole1Value.split("-")[0], //data.get("HCP-Role1").replace(/ /g, "").split("-")[0],
            HCP_TIH2: HCPRole2Value.split("-")[0], //data.get("HCP-Role2").replace(/ /g, "").split("-")[0],
            HCP_TEN: HCPTendancyValue, //data.get("HCPTendency").replace(/ /g, "").split("-")[0],
            HCP_PRIM_FLAG: PrimaryAffilValue.split("-")[0], //data.get("PrimaryAffiliations"),
            HCO_STATE: HCOStateValue.split("-")[0], //data.get("HCOState").replace(/ /g, "").split("-")[0],
            HCO_SP: HCOSpecialtyValue.split("-")[0], //data.get("HCOSpeciality").replace(/ /g, "").split("-")[0],
            HCO_TYP: HCOTypeValue, //data.get("HCO-Type").replace(/ /g, "").split("-")[0],
            HCO_SECTOR: HCOSectorValue.split("-")[0], //data.get("HCOSector").replace(/ /g, "").split("-")[0],
            HCO_MC: MetroCountryValue.split("-")[0], //data.get("MetroCountry"),
            HCO_POSTCODE: data.get("PostCode"),
            HCO_NAME_PART: data.get("WKP_Name"),
            MAIL_STATUS: MailStatusValue.split("-")[0], //data.get("MailStatus"),
            FAX_STATUS: FaxStatusValue.split("-")[0], //data.get("FAXStatus"),
            CALL_STATUS: CallStatusValue.split("-")[0], //data.get("CallStatus"),
            EMAIL_STATUS: EmailStatusValue.split("-")[0], //data.get("EMailStatus"),
            OFFSET: 0,
            NO_OF_RECORDS: 500,
          }
        : {
            MATSOC: MATSOCValue, //data.get("MATSOC").split("-")[0],
            HCO_STATE: HCOStateValue.split("-")[0], //data.get("HCOState").replace(/ /g, "").split("-")[0],
            HCO_STATUS: HCOStatusValue,
            HCO_SP: HCOSpecialtyValue.split("-")[0], //data.get("HCOSpeciality").replace(/ /g, "").split("-")[0],
            HCO_TYP: HCOTypeValue, //data.get("HCO-Type").replace(/ /g, "").split("-")[0],
            HCO_SECTOR: HCOSectorValue.split("-")[0], //data.get("HCOSector").replace(/ /g, "").split("-")[0],
            HCO_MC: MetroCountryValue.split("-")[0], //data.get("MetroCountry"),
            HCO_POSTCODE: data.get("PostCode"),
            HCO_NAME_PART: data.get("WKP_Name"),
            MAIL_STATUS: MailStatusValue.split("-")[0], //data.get("MailStatus"),
            FAX_STATUS: FaxStatusValue.split("-")[0], //data.get("FAXStatus"),
            CALL_STATUS: CallStatusValue.split("-")[0], //data.get("CallStatus"),
            EMAIL_STATUS: EmailStatusValue.split("-")[0], //data.get("EMailStatus"),
            OFFSET: 0,
            NO_OF_RECORDS: 500,
          };
    dispatch(updateparama(body));
    // dispatch(request({ body: body, country: CountryCode, userTyp: selectedUserType, method: "POST" })).then(() => {
    //    // setLoading((prevLoading) => !prevLoading);
    //     //setShowWarning(true);
    // });

    dispatch(
      report({
        body: body,
        country: CountryCode,
        userTyp: selectedUserType,
        method: "POST",
      })
    ).then(() => {
      //setLoading((prevLoading) => !prevLoading);
      // setLoading(false);
      setShowProgressReport(false);
      history.push("/Report");
    });
    //if (HCPRecords>0)
  };
  return (
    <Container component="main" maxWidth="ls">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "full",
          paddingTop: "20px",
        }}
      >
        <Box>
          <div className="form-group container">
            <div className="form-group row">
              <div className="form-group  col-lg-3">
                {/* <label for="MATSOC" className=" col-form-label col-lg-9">
                  MATSOC
                </label> */}
                <div className="col-sm-12">
                  <FormControl sx={{ width: 225 }}>
                    <InputLabel id="demo-simple-select-label">
                      MATSOC
                    </InputLabel>
                    <Select
                      name="MATSOC"
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      multiple
                      value={MATSOCValue}
                      onChange={handleChangeMatsoc}
                      //className="form-control col-sm-12"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {MATSOC?.map((item) => (
                        <MenuItem
                          key={item.key}
                          value={item.key}
                          style={getStyles(item, MATSOCValue, theme)}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/*                      <FormControl fullWidth>*/}
                  {/*  <InputLabel id="demo-simple-select-label">*/}
                  {/*    MATSOC*/}
                  {/*  </InputLabel>*/}
                  {/*  <Select*/}
                  {/*    labelId="demo-simple-select-label"*/}
                  {/*    id="demo-simple-select"*/}
                  {/*    value={MATSOCValue}*/}
                  {/*    label="MATSOC"*/}
                  {/*    onChange={handleChangeMatsoc}*/}
                  {/*    //className="form-control col-sm-12"*/}
                  {/*  >*/}
                  {/*    */}
                  {/* <option value="saab">Saab</option>*/}
                  {/*    {MATSOC.map((item) => (*/}
                  {/*      <MenuItem value={item}>{item}</MenuItem>*/}
                  {/*    ))}*/}
                  {/*  </Select>*/}
                  {/*</FormControl>*/}
                </div>
              </div>
              {selectedUserType == "HCP" && (
                <React.Fragment>
                  <div className="form-group  col-lg-3">
                    {/* <label
                      for="IndividualStatus"
                      className=" col-form-label col-lg-9"
                    >
                      Individual Status
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Individual Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={IndividualStatusValue}
                          label="Individual Status"
                          onChange={handleChangeIndividualStatus}
                          // className="form-control col-sm-12"
                        >
                          <MenuItem value="All">All</MenuItem>
                          {IndividualStatus.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    {/* <label
                      for="ActivityStatus"
                      className=" col-form-label col-lg-9"
                    >
                      Activity Status
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Activity Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ActivityStatusValue}
                          label="Activity Status"
                          onChange={handleChangeActivityStatus}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {/* <option value="saab">Saab</option>*/}
                          {ActivityStatus.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group  col-lg-3">
                    {/* <label for="HCP_Type" className=" col-form-label col-lg-9">
                      HCP Type
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          HCP Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={HCPTypeValue}
                          label="HCP Type"
                          onChange={handleChangeHCPType}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {HCP_Type.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    <label
                      for="HCP  Speciality Selection"
                      className="col-form-label col-lg-12"
                    >
                      HCP Speciality Selection
                    </label>
                    <div className="col-sm-12 row">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="AND"
                        name="radio-buttons-group"
                        id="HCP_Spl"
                        class="col-lg-12"
                      >
                        <FormControlLabel
                          value="AND"
                          className="col-lg-6"
                          control={<Radio />}
                          label="AND"
                        />
                        <FormControlLabel
                          value="OR"
                          className="col-lg-4"
                          control={<Radio />}
                          label="OR"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    <div className="col-sm-12">
                      <FormControl sx={{ width: 225 }}>
                        <InputLabel id="demo-simple-select-label">
                          HCP Specialty1
                        </InputLabel>
                        <Select
                          name="HCPSpeciality1"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          multiple
                          value={specialty1Value}
                          onChange={handleChangeSpecialty1}
                          //className="form-control col-sm-12"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {/* <option value="saab">Saab</option>*/}
                          <MenuItem key="All" value="All">
                            All
                          </MenuItem>
                          {HCPSpecialty.map((item) => (
                            <MenuItem
                              key={item.key}
                              value={item.key}
                              style={getStyles(item, specialty1Value, theme)}
                            >
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    {/* <label
                      for="HCP Speciality2"
                      className="col-form-label col-lg-9"
                    >
                      HCP Speciality2
                    </label> */}
                    <div className="col-sm-12">
                      {/* <select
                        id="HCPSpeciality2"
                        name="HCPSpeciality2"
                        className="form-control col-sm-12"
                      >
                        <option value="All">All</option>
                        {HCPSpecialty.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select> */}
                      <FormControl sx={{ width: 225 }}>
                        <InputLabel id="demo-simple-select-label">
                          HCP Specialty2
                        </InputLabel>
                        <Select
                          name="HCPSpeciality2"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          multiple
                          value={specialty2Value}
                          onChange={handleChangeSpecialty2}
                          //className="form-control col-sm-12"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {/* <option value="saab">Saab</option>*/}
                          <MenuItem key="All" value="All">
                            All
                          </MenuItem>
                          {HCPSpecialty.map((item) => (
                            <MenuItem
                              key={item.key}
                              value={item.key}
                              style={getStyles(item, specialty2Value, theme)}
                            >
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    {/* <label
                      for="HCP Speciality3"
                      className="col-form-label col-lg-9"
                    >
                      HCP Speciality3
                    </label> */}
                    <div className="col-sm-12">
                      {/* <select
                        id="HCPSpeciality3"
                        name="HCPSpeciality3"
                        className="form-control col-sm-12"
                      >
                        <option value="All">All</option>
                        {HCPSpecialty.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select> */}
                      <FormControl sx={{ width: 225 }}>
                        <InputLabel id="demo-simple-select-label">
                          HCP Specialty3
                        </InputLabel>
                        <Select
                          name="HCPSpeciality3"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          multiple
                          value={specialty3Value}
                          onChange={handleChangeSpecialty3}
                          //className="form-control col-sm-12"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {/* <option value="saab">Saab</option>*/}
                          <MenuItem key="All" value="All">
                            All
                          </MenuItem>
                          {HCPSpecialty.map((item) => (
                            <MenuItem
                              key={item.key}
                              value={item.key}
                              style={getStyles(item, specialty3Value, theme)}
                            >
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group  col-lg-3">
                    {/* <label
                      for="PrimaryAffiliations"
                      className=" col-form-label col-lg-9"
                    >
                      Primary Affiliations
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Primary Affiliations
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={PrimaryAffilValue}
                          label="Primary Affiliations"
                          onChange={handleChangePrimaryAffil}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {HCPPRIMARYAFFILIATION.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    {/* <label for="HCP-Role1" className=" col-form-label col-lg-9">
                      HCP Role1
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          HCP Role1
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={HCPRole1Value}
                          label="HCP Role1"
                          onChange={handleChangeHCPRole1}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {HCPRole.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group  col-lg-3">
                    {/* <label for="HCP-Role2" className=" col-form-label col-lg-9">
                      HCP Role2
                    </label> */}
                    <div className="col-sm-12">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          HCP Role2
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={HCPRole2Value}
                          label="HCP Role2"
                          onChange={handleChangeHCPRole2}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {HCPRole.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-group   col-lg-3">
                    {/* <label
                      for="HCP Tendency"
                      className="col-form-label col-lg-9"
                    >
                      HCP Tendency
                    </label> */}
                    <div className="col-sm-12">
                      {/* <Select
                        id="HCP-Tendency"
                        name="HCPTendency"
                        className="form-control col-sm-12"
                      >
                        <MenuItem value="All">All</MenuItem>
                        {HCPTendency.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select> */}
                      <FormControl sx={{ width: 225 }}>
                        <InputLabel id="demo-simple-select-label">
                          HCP Tendency
                        </InputLabel>
                        <Select
                          name="HCPSpeciality1"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          multiple
                          value={HCPTendancyValue}
                          onChange={handleChangeHCPTendency}
                          //className="form-control col-sm-12"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {/* <option value="saab">Saab</option>*/}
                          <MenuItem key="All" value="All">
                            All
                          </MenuItem>
                          {HCPTendency.map((item) => (
                            <MenuItem
                              key={item.key}
                              value={item.key}
                              style={getStyles(item, HCPTendancyValue, theme)}
                            >
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>{" "}
                </React.Fragment>
              )}

              {/*  */}
              <div className="form-group  col-lg-3">
                {/* <label for="HCO Type" className=" col-form-label col-lg-9">
                  HCO Type
                </label> */}
                <div className="col-sm-12">
                  {/* <Select
                    id="HCO-Type"
                    name="HCO-Type"
                    className="form-control col-sm-12"
                  >
                    <MenuItem value="All">All</MenuItem>
                    {HCOType.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select> */}
                  <FormControl sx={{ width: 225 }}>
                    <InputLabel id="demo-simple-select-label">
                      HCO Type
                    </InputLabel>
                    <Select
                      name="HCPSpeciality1"
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      multiple
                      value={HCOTypeValue}
                      onChange={handleChangeHCOType}
                      //className="form-control col-sm-12"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {/* <option value="saab">Saab</option>*/}
                      <MenuItem key="All" value="All">
                        All
                      </MenuItem>
                      {HCOType.map((item) => (
                        <MenuItem
                          key={item.key}
                          value={item.key}
                          style={getStyles(item, HCOTypeValue, theme)}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group   col-lg-3">
                {/* <label for="HCOSpeciality" className=" col-form-label col-lg-9">
                  HCO Speciality
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      HCO Speciality
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={HCOSpecialtyValue}
                      label="HCO Speciality"
                      onChange={handleChangeHCOSpecialty}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {HCOSpeciality.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group  col-lg-3">
                {/* <label for="HCOSector" className=" col-form-label col-lg-9">
                  HCO Sector
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      HCO Sector
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={HCOSectorValue}
                      label="HCO Sector"
                      onChange={handleChangeHCOSector}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {HCOSector.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group   col-lg-3">
                {/* <label for="Metro Country" className="col-form-label col-lg-9">
                  Metro Country
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Metro Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={MetroCountryValue}
                      label="Metro Country"
                      onChange={handleChangeMetroCountry}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {HCOMetroCountry.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/*  */}
              <div className="form-group  col-lg-3">
                {/* <label for="HCOState" className=" col-form-label col-lg-9">
                  HCOState
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      HCO State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={HCOStateValue}
                      label="HCO State"
                      onChange={handleChangeHCOState}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {HCOState.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {selectedUserType == "HCO" && (
              <div className="form-group  col-lg-3">
                {/* <label for="HCOState" className=" col-form-label col-lg-9">
                  HCOState
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      HCO Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={HCOStatusValue}
                      label="HCO Status"
                      onChange={handleChangeHCOStatus}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="3">Open</MenuItem>
                      <MenuItem value="9">Closed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>)}
              <div className="form-group   col-lg-3">
                {/*<label for="PostCode" className=" col-form-label col-lg-9">*/}
                {/*    Post Code*/}
                {/*</label>*/}
                <div className="col-sm-12">
                  <TextField
                    margin="normal"
                    //required
                    //fullWidth
                    // style={{ width: "60%" }}
                    id="PostCode"
                    label="Post Code"
                    name="PostCode"
                    variant="standard"
                    // autoComplete="email"
                    // autoFocus
                    // className="form-control col-sm-12"
                  />
                </div>
              </div>
              <div className="form-group  col-lg-3">
                {/*<label for="WKPName" className=" col-form-label col-lg-9">*/}
                {/*    WKP Name*/}
                {/*</label>*/}
                <div className="col-sm-12">
                  {/*<select id="WKPName" name="WKPName" className="form-control col-sm-12">*/}
                  {/*    <option value="All">All</option>*/}
                  {/*    <option value="saab">Saab</option>*/}
                  {/*</select>*/}
                  <TextField
                    margin="normal"
                    //required
                    //fullWidth
                    // style={{ width: "60%" }}
                    id="WKP_Name"
                    label="WKP Name"
                    name="WKP_Name"
                    variant="standard"
                    // autoComplete="email"
                    // autoFocus
                    // className="form-control col-sm-12"
                  />
                </div>
              </div>
              <div className="form-group   col-lg-3">
                {/* <label for="MAilStatus" className="col-form-label col-lg-9">
                  Mail Status
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mail Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={MailStatusValue}
                      label="Mail Status"
                      onChange={handleChangeMailStatus}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="MAIL OK Only">MAIL OK Only</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/*  */}
              <div className="form-group  col-lg-3">
                {/* <label for="FAXStatus" className=" col-form-label col-lg-9">
                  FAX Status
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      FAX Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={FaxStatusValue}
                      label="FAX Status"
                      onChange={handleChangeFaxStatus}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="FAX OK Only">FAX OK Only</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group   col-lg-3">
                {/* <label for="CallStatus" className=" col-form-label col-lg-9">
                  Call Status
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Call Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={CallStatusValue}
                      label="Call Status"
                      onChange={handleChangeCallStatus}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="CALL OK Only">CALL OK Only</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group   col-lg-3">
                {/* <label for="EmailStatus" className=" col-form-label col-lg-9">
                  EMail Status
                </label> */}
                <div className="col-sm-12">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      EMail Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={EmailStatusValue}
                      label="EMail Status"
                      onChange={handleChangeEmailStatus}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="EMAIL OK Only">EMAIL OK Only</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {
                // Submit Button
              }
              <div className="form-group col-lg-3"></div>
              <div className="form-group col-lg-3"></div>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <button
                    type="reset"
                    style={{ marginTop: "5%", background: "#2E3B55" }}
                    className="btn btn-primary col-sm-12"
                  >
                    Reset
                  </button>
                </Grid>
                <Grid item xs={3}>
                  <button
                    type="submit"
                    style={{ marginTop: "5%", background: "#2E3B55" }}
                    className="btn btn-primary col-sm-12"
                  >
                    Fetch Report
                  </button>
                  {invalidMATSOC && (
                    <Alert severity="warning">Please select MATSOC.</Alert>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {loading && (
                    <Alert severity="info">
                      <Fade
                        in={true}
                        style={{
                          transitionDelay: loading ? "800ms" : "0ms",
                        }}
                        unmountOnExit
                      >
                        <CircularProgress />
                      </Fade>{" "}
                      Please wait ....{" "}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showProgressReport}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
