import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Logo } from '../../Assets/logo.svg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
//import { country } from './layoutSlice'
import { 
    updateuserTypet,
    updatecountry
} from "../Request/RequestSlice";



export  function Layout() {
    const loginState = useSelector((state) => state.login);
    const { user,isAuthorised } = useSelector(
      (state) => state.authentication
    );
    const location = useLocation(); 
    const requestState = useSelector((state) => state.request);
    //const { CountryCode, setCountryCode } = requestState;
    const { selectedUserType, RecordCount, MATSOC, IndividualStatus, ActivityStatus, HCP_Type, HCPSpecialty, HCPPRIMARYAFFILIATION, HCPRole, HCPTendency, HCOType, HCOSector, HCOState, HCOSpeciality, CountryCode, HCOMetroCountry } = requestState;
    const dispatch = useDispatch();
    //const layoutState = useSelector((state) => state.layout);
    //const [selectedUserType, selectedCountry] = layoutState;
    const [Country, setCountry] = React.useState('AU');
    const [userType, setuserType] = React.useState('HCP');
    const [open, setOpen] = React.useState(false);

 
    const handleChange = (event) => {
        setCountry(event.target.value);
        dispatch(updatecountry(event.target.value));
      //   dispatch(getMATSOC({  country: event.target.value , method: "GET" }));
      //   dispatch(getIndividualStatus({ country: event.target.value , method: "GET" }));
      //   dispatch(getActivityStatus({ country: event.target.value , method: "GET" }));
      //   dispatch(getHCP_Type({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCPSpecialty({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCPPRIMARYAFFILIATION({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCPRole({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCPTendency({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCOType({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCOSector({ country: event.target.value , CountryCode, method: "GET" }));
      //  // dispatch(GetHCOMetroCountry({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCOState({ country: event.target.value , method: "GET" }));
      //   dispatch(GetHCOSpeciality({ country: event.target.value , method: "GET" }));
      //   //setCountryCode(event.target.value);countrysCountry
      //   dispatch(getCountry({ country: event.target.value, method: "GET" }));
        //method: "GET" 
    };
    const handleUChange = (event) => {
      //alert(location.pathname=='/Request');
        setuserType(event.target.value);
       // dispatch(getuserType({ uType: event.target.value,method: "GET" }))
        dispatch(updateuserTypet(event.target.value));
    };
    //const handleClose = () => {
    //     setOpen(false);
    //};

    //const handleOpen = () => {
    //     setOpen(true);
    //};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar  style={{ background: '#2E3B55' }} position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                     
                  <Logo/>
                  </Typography>
                  {location.pathname=='/Request' &&
                      <div>
                      <FormControl sx={{ m: 1, minWidth: 100, height: 40, marginBottom: 3, marginTop: 1, marginRight: 0, display: {} }} >
                          <InputLabel id="demo-controlled-open-select-label" style={{ color: "#fff", paddingLeft:2 }} >User Type</InputLabel>

                          </FormControl>
                      <FormControl sx={{ m: 1, minWidth: 240, height: 40, marginBottom: 2, marginTop: 1 }} >
                         {/* <InputLabel id="demo-controlled-open-select-label">User Type</InputLabel>*/}
                          <Select
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select-user"
                              //open={open}   Data Extraction Tool 
                              //onClose={handleClose}
                              //onOpen={handleOpen}
                              value={userType}
                              label="User Type"
                              onChange={handleUChange}
                              style={{ color: "#fff", border: "inset", height: 40, marginTop: 9 }} 
                          >
                                  
                              <MenuItem value={'HCP'}>Healthcare Professional</MenuItem>
                                  <MenuItem value={'HCO'}>Healthcare Organisation </MenuItem>
                          </Select>
                      </FormControl>


                          <FormControl sx={{ m: 1, minWidth: 100, height: 40, marginBottom: 2, marginTop: 1, marginRight: 0, display: {} }} >
                              <InputLabel id="demo-controlled-open-select-label" style={{ color: "#fff", paddingLeft:16 }} >Country</InputLabel>
                              
                          </FormControl>
                  
                      <FormControl sx={{ m: 1, minWidth: 200,  height: 40, marginBottom: 2, marginTop: 1, marginLeft: 0 }}  >
                          {/*<InputLabel id="demo-controlled-open-select-label" >Country</InputLabel>*/}
                      <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                         // open={open}
                          //onClose={handleClose}
                          //onOpen={handleOpen}
                              value={Country}
                              label="Country"
                            //  style={{  }}
                              onChange={handleChange}
                              style={{ color: "#fff", border: "inset", height: 40, marginTop:9 }} 
                      >
                         
                          <MenuItem value={"AU"}>Australia</MenuItem>
                          <MenuItem value={"NZ"}>New Zealand</MenuItem>
                      </Select>
                      </FormControl> </div>
                  
                
                  
               }

          {isAuthorised && user.userName && (
            <div>
              <Menu
                id="menu-appbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
              </Menu>
              <Typography variant="h10" component="div" sx={{ flexGrow: 1}}>
              {user.userName}
          </Typography>

            </div>
           
                  )}

                  <div>
          
            </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}