import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import { ArrowBack } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { columnsHCO } from "./ColumnsHCO";
import { columnsHCP } from "./ColumnsHCP";
import { columnsHCO_External } from "./ColumnsHCO_External";
import { columnsHCP_External } from "./ColumnsHCP_External";

export default function Report() {
  const requestState = useSelector((state) => state.request);
  const history = useHistory();
  const visibilitModelHCP = {
    id: false,
    mailstatus: false,
    faxstatus: false,
    phonestatus: false,
    wkpemailstatus: false,
    mailingaddresS1: false,
    mailingaddresS2: false,
    mailingaddresS3: false,
    mailingsuburb: false,
    mailingstate: false,
    mailingpostcode: false,
    organisationidL1: false,
    organisationidL2: false,
    organisationidL3: false,
    organisationnameL1: false,
    organisationnameL2: false,
    organisationnameL3: false,
    specialinteresT1: false,
    specialinteresT2: false,
    specialinteresT3: false,
    specialinteresT4: false,
    specialinteresT5: false,
    publicprivate: false,
    addresS1: false,
    addresS2: false,
    addresS3: false,
    specialtY2: false,
    specialtY3: false,
    accountspecialty: false,
    rolE2: false,
  };
  const visibilitModelHCO = {
    id: false,
    mailstatus: false,
    faxstatus: false,
    phonestatus: false,
    wkpemailstatus: false,
    mailingaddresS1: false,
    mailingaddresS2: false,
    mailingaddresS3: false,
    mailingsuburb: false,
    mailingstate: false,
    mailingpostcode: false,
    organisationidL1: false,
    organisationidL2: false,
    organisationidL3: false,
    organisationnameL1: false,
    organisationnameL2: false,
    organisationnameL3: false,
    bannergroupid: false,
    bannergroupname: false,
    addresS2: false,
    addresS3: false,
    publicprivate: false,
    addresS1: false,
  };
  //  const { para } = requestState;
  const { RecordCount, HCPRecords, params, selectedUserType, CountryCode } =
    requestState;

  const { user, isAuthorised } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (!isAuthorised) {
      history.push("/");
    }
  }, [isAuthorised]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {!user.readOnly && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  }

  function GetGridColumns() {
    if (selectedUserType == "HCO") {
      if (user.externalUser) {
        return columnsHCO_External;
      } else {
        return columnsHCO;
      }
    } else {
      if (user.externalUser) {
        return columnsHCP_External;
      } else {
        return columnsHCP;
      }
    }
  }

  return (
    <React.Fragment>
      <div className="col-sm-3" style={{ marginBottom: 20 }}>
        <IconButton
          color="primary"
          aria-label=""
          onClick={() => {
            history.push("/Request");
          }}
        >
          <ArrowBack />
        </IconButton>
        {/* <Alert severity="info">Records Count: {RecordCount}</Alert> */}
      </div>

      <Container component="main" maxWidth="lg">
        <Box sx={{ height: 900, width: "100%" }}>
          {/* <DataGrid
               rows={HCPRecords}
               columns={selectedUserType == "HCO" ? columnsHCO : columnsHCP}
                //initialState={{
                //    pagination: {
                 //       paginationModel: {
                 //           pageSize: 100,
                 //       },
                 //   },
                //}}
                columnVisibilityModel={selectedUserType == "HCO" ? visibilitModelHCO: visibilitModelHCP}
                slots={{ toolbar: GridToolbar }} 
              //  pageSizeOptions={[10]}
            /> */}
          <DataGrid
            rows={HCPRecords}
            columns={GetGridColumns()}
            slots={{ toolbar: CustomToolbar }}
            getRowId={(row) => {
              return selectedUserType == "HCO"
                ? row.workplaceid
                : row.hcpuniqueid + row.accountid;
            }}
            // {...HCPRecords}
            initialState={{
              ...HCPRecords?.initialState,
              columns: {
                ...HCPRecords?.initialState?.columns,
                columnVisibilityModel:
                  selectedUserType == "HCO"
                    ? visibilitModelHCO
                    : visibilitModelHCP,
              },
            }}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}
