import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Alert from '@mui/material/Alert';
import image from '../../Assets/background.jpg';
//import Link from '@mui/material/Link';
import {
    login,

} from "./loginSlice";

//import { ReactComponent as Logo } from '../../Assets/logo.svg';

export default function Login() {

    const history = useHistory();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const {  authenticated,redirectUrl,loginStatus,isLoggedIn } = loginState;

    const {  isAuthorised } = useSelector((state) => state.authentication);

    const [showWarning, setShowWarning] = useState(false);
    //dispatch(updateAuthorized(false));
 

    useEffect(() => {
        if(isAuthorised){
         history.push('/Request');
         }
    
      }, [isAuthorised]);

    if (loginStatus == "" || loginStatus == "pending") {
        console.log("Loading...");
      }
      if (loginStatus == "success" && isLoggedIn) {
        console.log("Logged In", redirectUrl);
        window.location.href = redirectUrl;
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let body = {
            userEmail: data.get("email"),
            password: data.get("password"),
        }

        dispatch(login({ body: body, method: "POST" })).then(() => {

            setShowWarning(true);
        });

    };

    return (


        <Container component="main" maxWidth="ls" direction="row" className="row" sx={{ flexDirection: 'flex-start', paddingLeft: '0px !Important', paddingRight: '0px !important' }} style={{ paddingLeft: "0px !important" }}>
            {/**Align Box to right half */}
            <div className="row" style={{ paddingLeft: "0px !important", marginleft: '0px !Important', paddingRight: '0px !important' }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        justifyContent: 'flex-start',
                        flexDirection: "column",
                        alignItems: "center",
                        className: "col-md-5",
                        width: '60%',

                    }}
                    style={{ marginTop: 0 }}
                >
                    
                    <img
                        src={image}
                        width={"100%"}
                        height={"100%"}
                        alt="img"
                    />
                 
                </Box>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "inline-flex",
                        justifyContent: 'flex-end',
                        flexDirection: "column",
                        alignItems: "center",
                        className: "col-md-8",
                        width: '40%',
                    }}
                    style={{ background: '#2E3B55', marginTop: 0 }} position="static"
                >
                    <Box
                        sx={{
                            marginTop: 8,
                            marginBottom: 8,
                            display: "flex",
                            justifyContent: 'flex-start',
                            flexDirection: "column",
                            alignItems: "center",
                            className: "col-md-12",
                            border: '1px solid',
                            width: '80%',
                            height: '80%',
                            bgcolor: 'background.paper', borderRadius: 2,
                        }}
                        style={{ marginTop: 0 }}

                    >
                        <Typography component="h3" variant="h6" style={{marginBottom:5,marginTop:150}}>
                            Sign In
                        </Typography>
                        <span component="h1" variant="h5">
                            Access to your One Key Application
                        </span>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                            display: 'flex',
                            //alignItems: 'flex-start',
                            flexDirection: 'column',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                            width: '80%',
                            borderRadius: 1, alignItems: "center",
                        }}>
                            {/* <TextField
                                margin="normal"
                                required
                                // fullWidth
                                style={{ width: "60%" }}
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            // className="col-lg-9"
                            />
                            <TextField
                                margin="normal"
                                required
                                // fullWidth
                                style={{ width: "60%" }}
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            /> */}
                            
                            <Button
                                type="submit"
                                //fullWidth
                                style={{ width: "60%" }}
                                color="success"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            // className="col-lg-8"
                            >
                                 SSO Log In
                            </Button>
                            
                            {/* {showWarning && !authenticated && <Alert severity="warning">Invalid Credentials</Alert>} */}
                        </Box>
                        {/*<Link href="#" className="Mui-focusVisible" color="inherit" >*/}
                        {/*     Need Help?*/}
                        {/*</Link>*/}
                    </Box>
                </Box>
            </div>
        </Container>

    );
}
