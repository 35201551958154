export const columnsHCP_External = [  
    // {
    //     field: 'id',
    //     headerName: 'ID',
    //     width: 10,
    //     typeof: Number,       
    // },
    {
        field: 'hcpuniqueid',
        headerName: 'HCP UNIQUE ID',
        width: 150
    },
    {
        field: 'title',
        headerName: 'TITLE',
        width: 50,       
    },
    {
        field: 'indtype',
        headerName: 'IND TYPE',
        width: 120,       
    },
    {
        field: 'firstname',
        headerName: 'FIRST NAME',       
        width: 100,        
    },
    {
        field: 'lastname',
        headerName: 'LAST NAME',     
        width: 100,       
    },
    {
        field: 'specialty',
        headerName: 'SPECIALTY',
        width: 120,       
    },
    {
        field: 'specialtY2',
        headerName: 'SPECIALTY2',
        width: 120,       
    },
    {
        field: 'specialtY3',
        headerName: 'SPECIALTY3',
        width: 120,       
    },
    {
        field: 'rolE1',
        headerName: 'ROLE1',
        width: 120,       
    },
    {
        field: 'rolE2',
        headerName: 'ROLE2',
        width: 120,       
    },
    {
        field: 'specialinteresT1',
        headerName: 'SPECIAL INTEREST1',
        width: 120,       
    },
    {
        field: 'specialinteresT2',
        headerName: 'SPECIAL INTEREST2',
        width: 120,       
    },
    {
        field: 'specialinteresT3',
        headerName: 'SPECIAL INTEREST3',
        width: 120,       
    },
    {
        field: 'specialinteresT4',
        headerName: 'SPECIAL INTEREST4',
        width: 120,       
    },
    {
        field: 'specialinteresT5',
        headerName: 'SPECIAL INTEREST5',
        width: 120,       
    },
    {
        field: 'individualstatus',
        headerName: 'INDIVIDUAL STATUS',
        width: 120,       
    },
    {
        field: 'activitystatus',
        headerName: 'ACTIVITY STATUS',
        width: 120,       
    },
    {
        field: 'organisationidL3',
        headerName: 'ORGANISATION ID L3',
        width: 120,       
    },
    {
        field: 'organisationnameL3',
        headerName: 'ORGANISATION NAME L3',
        width: 120,       
    },
    {
        field: 'organisationidL2',
        headerName: 'ORGANISATION ID L2',
        width: 120,       
    },
    {
        field: 'organisationnameL2',
        headerName: 'ORGANISATION NAME L2',
        width: 120,       
    },
    {
        field: 'organisationidL1',
        headerName: 'ORGANISATION ID L1',
        width: 120,       
    },
    {
        field: 'organisationnameL1',
        headerName: 'ORGANISATION NAME L1',
        width: 120,       
    },
    {
        field: 'accountid',
        headerName: 'ACCOUNT ID',
        width: 150,       
    },
    {
        field: 'accountname',
        headerName: 'ACCOUNT NAME',
        width: 250,       
    },
    {
        field: 'accounttype',
        headerName: 'ACCOUNT TYPE',
        width: 250,       
    },
    {
        field: 'accountspecialty',
        headerName: 'ACCOUNT SPECIALTY',
        width: 250,       
    },
    {
        field: 'publicprivate',
        headerName: 'PUBLIC PRIVATE',
        width: 250,       
    },
    {
        field: 'primaryaffiliation',
        headerName: 'PRIMARY AFFILIATION',
        width: 250,       
    },
    {
        field: 'addresS1',
        headerName: 'ADDRESS1',
        width: 250,       
    },
    {
        field: 'addresS2',
        headerName: 'ADDRESS2',
        width: 250,       
    },
    {
        field: 'addresS3',
        headerName: 'ADDRESS3',
        width: 250,       
    },
    {
        field: 'suburb',
        headerName: 'SUBURB',
        width: 120,       
    },
    {
        field: 'state',
        headerName: 'STATE',
        width: 120,       
    },
    {
        field: 'postcode',
        headerName: 'POST CODE',
        width: 100,        
    },
    {
        field: 'mailingaddresS1',
        headerName: 'MAILING ADDRESS1',
        width: 120,        
    },
    {
        field: 'mailingaddresS2',
        headerName: 'MAILING ADDRESS2',
        width: 120,        
    },
    {
        field: 'mailingaddresS3',
        headerName: 'MAILING ADDRESS3',
        width: 120,        
    },
    {
        field: 'mailingsuburb',
        headerName: 'MAILING SUBURB',
        width: 120,        
    },
    {
        field: 'mailingstate',
        headerName: 'MAILING STATE',
        width: 120,        
    },
    {
        field: 'mailingpostcode',
        headerName: 'MAILING POSTCODE',
        width: 120,        
    },
    {
        field: 'metrocountry',
        headerName: 'METRO COUNTRY',
        width: 120,        
    },
    {
        field: 'brickcode',
        headerName: 'BRICK CODE',
        width: 120,        
    },
    {
        field: 'brickdesc',
        headerName: 'BRICK DESC',
        width: 120,        
    },
    {
        field: 'phonenumber',
        headerName: 'PHONE NUMBER',
        width: 180,       
    },
    {
        field: 'faxnumber',
        headerName: 'FAX NUMBER',
        width: 120,        
    },
    {
        field: 'mailstatus',
        headerName: 'MAIL STATUS',
        width: 120,        
    },
    {
        field: 'faxstatus',
        headerName: 'FAX STATUS',
        width: 120,        
    },
    {
        field: 'phonestatus',
        headerName: 'PHONE STATUS',
        width: 120,        
    },
    {
        field: 'wkpemailstatus',
        headerName: 'WKPE MAILSTATUS',
        width: 120,        
    },

    {
        field: 'pseudohcpuniqueid',
        headerName: 'PSEUDO HCP UNIQUE ID',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL1',
        headerName: 'PSEUDO ORGANISATION ID L1',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL2',
        headerName: 'PSEUDO ORGANISATION ID L2',
        width: 180,        
    },
    {
        field: 'pseudoorganisationidL3',
        headerName: 'PSEUDO ORGANISATION ID L3',
        width: 180,          
    },
    {
        field: 'pseudoaccountid',
        headerName: 'PSEUDO ACCOUNT ID',
        width: 180,        
    },
    {
        field: 'individualfreshness',
        headerName: 'INDIVIDUAL FRESHNESS',
        width: 180,        
    },
    {
        field: 'activityfreshness',
        headerName: 'ACTIVITY FRESHNESS',
        width: 180,        
    },
    {
        field: 'workplacefreshness',
        headerName: 'WORKPLACE FRESHNESS',
        width: 180,        
    }
];