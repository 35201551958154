import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const request = createAsyncThunk(
    "report/GetReportHCP",  //GetReportHCPWithPaging
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
      reqOptions.headers = headers;
      let selectedCountry = obj.country == "NZ" ? obj.country : "AU";
      let actionMethod = obj.userTyp == "HCO" ? "GetReportHCOCount" : "GetReportHCPCount";
      return fetch("api/Report/" + actionMethod + "?country=" + selectedCountry, reqOptions).then((r) => {
      
        if (r.status === 401) {
            dispatch(endUserSession());
          } else {
            return r.json();
          }
      

    });
    }

);
export const report = createAsyncThunk(
    "report/GetReportHCPWithPaging",  //GetReportHCPWithPaging
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        let headers = new Headers({
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: 0,
        });
        headers.set("Access-Control-Allow-Origin", "*");
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        reqOptions.method = obj.method;
        reqOptions.body = JSON.stringify(obj.body);
        reqOptions.headers = headers;
        let selectedCountry = obj.country == "NZ" ? obj.country : "AU";
        let actionMethod = obj.userTyp == "HCO" ? "GetReportHCOWithPaging" : "GetReportHCPWithPaging";
        return fetch("api/Report/" + actionMethod + "?country=" + selectedCountry, reqOptions).then((r) => {

            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }

);
export const getMATSOC = createAsyncThunk(
    "Filter/GetMatsoc",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetMatsoc?country=" + obj.country + "&matsoc="+ obj.matsoc).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//getIndividualStatus
export const getIndividualStatus = createAsyncThunk(
    "Filter/GetHCPINDStatus",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPINDStatus?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//ActivityStatus
export const getActivityStatus = createAsyncThunk(
    "Filter/GetHCPACTStatus",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPACTStatus?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//getHCP_Type
export const getHCP_Type = createAsyncThunk(
    "Filter/GetHCPType",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPType?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);

//GetHCPSpecialty
export const GetHCPSpecialty = createAsyncThunk(
    "Filter/GetHCPSpecialty",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPSpecialty?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCPPRIMARYAFFILIATION
export const GetHCPPRIMARYAFFILIATION = createAsyncThunk(
    "Filter/GetHCPPRIMARYAFFILIATION",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPPRIMARYAFFILIATION?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCPRole
export const GetHCPRole = createAsyncThunk(
    "Filter/GetHCPRole",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPRole?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCPTendency
export const GetHCPTendency = createAsyncThunk(
    "Filter/GetHCPTendency",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCPTendency?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCOType
export const GetHCOType = createAsyncThunk(
    "Filter/GetHCOType",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCOType?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCOSpecialty
export const GetHCOSpeciality = createAsyncThunk(
    "Filter/GetHCOSpeciality",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCOSpecialty?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCOSector
export const GetHCOSector = createAsyncThunk(
    "Filter/GetHCOSector",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCOSector?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCOMetroCountry
export const GetHCOMetroCountry = createAsyncThunk(
    "Filter/GetHCOMetroCountry",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCOMetroCountry?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//GetHCOState
export const GetHCOState = createAsyncThunk(
    "Filter/GetHCOState",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        return fetch("api/Filters/GetHCOState?country=" + obj.country).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }


        });
    }
);
//user Type
export const getuserType = createAsyncThunk(
    "Filter/getuserType",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        reqOptions.payload = obj.uType;
        return obj.uType;
        //state.selectedUserType = obj.userType;
        //return fetch("api/Filters/GetHCOState?country=" + obj.country).then((r) => {
        //    return r.json();
       // console.log(state.selectedUserType);

        //});
    }
);
//getCountry
export const getCountry = createAsyncThunk(
    "Filter/getCountry",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        reqOptions.method = obj.method;
        reqOptions.payload = obj.country;
        return obj.country;
        //state.selectedUserType = obj.userType;
        //return fetch("api/Filters/GetHCOState?country=" + obj.country).then((r) => {
        //    return r.json();
        // console.log(state.selectedUserType);

        //});
    }
);
const RequestSlice = createSlice({
    name: "Request",
  initialState: {
      MATSOC: [],
      IndividualStatus: [],
      ActivityStatus: [],
      HCP_Type: [],
      HCPSpecialty: [],
      HCPPRIMARYAFFILIATION: [],
      HCPRole: [],
      HCPTendency: [],
      HCOType: [],
      HCOSpeciality: [],
      HCOSector: [],
      HCOMetroCountry: [],
      HCOState: [],
      CountryCode: 'AU',
      selectedUserType: 'HCP',
      RecordCount: 0,
      HCPRecords: [],
      HCORecors: [],
      params: [],
      isSessionValid: true,
      
    loading: true,
  },
    reducers: {
        endUserSession(state, action) {
            state.isSessionValid = false;
          },
        updateparama(state, { payload }) {
            state.params = payload;
        },
        updateuserTypet(state, { payload }) {
            
            state.selectedUserType = payload;
            //state.CountryCode = 'AU';
        },
        updatecountry(state, { payload }) {
            
            state.CountryCode = payload;
            //state.CountryCode = 'AU';
        },
        updateRecordCount(state, { payload }) {
            
            state.RecordCount = payload;
            //state.CountryCode = 'AU';
        },
        updatActivityStatus(state, { payload }) {
            
            state.ActivityStatus = payload;

        },
     
    updateIndividualStatus(state, { payload }) {
        state.IndividualStatus = payload;
      },
      updateMATSOC(state, { payload }) {
          //console.log("test234==>",payload);
          state.MATSOC = payload;
      },
     
     // getHCP_Type
      updatHCPType(state, { payload }) {
          
          state.HCP_Type = payload;
      },
      updateHCPSpecialty(state, { payload }) {
          
          state.HCPSpecialty = payload;
      },
      updateHCPPRIMARYAFFILIATION(state, { payload }) {
          
          state.HCPPRIMARYAFFILIATION = payload;
      },
      updateHCPRole(state, { payload }) {
          
          state.HCPRole = payload;
      },
      updateHCPTendency(state, { payload }) {
          
          state.HCPTendency = payload;
      },
      //HCOType
      updateHCOType(state, { payload }) {
          
          state.HCOType = payload;
      },
      //HCOSpecialty
      updateHCOSpeciality(state, { payload }) {
          
          state.HCOSpeciality = payload;
      },
      //HCOSector
      updateHCOSector(state, { payload }) {
          
          state.HCOSector = payload;
      },
      //HCOMetroCountry
      updateHCOMetroCountry(state, { payload }) {
          
          state.HCOMetroCountry = payload;
      },
      //HCOState
      updateHCOState(state, { payload }) {
          
          state.HCOState = payload;
      },
  },
  extraReducers: {
  //[] User Type
      [getuserType.pending]: (state, action) => {
          state.status = "loading";

      },
      [getuserType.fulfilled]: (state, { payload }) => {

          state.status = "success";
          if (payload) {
              // state.authenticated=true;
              state.selectedUserType = payload;
          }
          else {
              //state.authenticated=false;
              state.selectedUserType = 'HCP';
          }

      },

      // Country
      [getCountry.pending]: (state, action) => {
          state.status = "loading";

      },
      [getCountry.fulfilled]: (state, { payload }) => {

          state.status = "success";
          if (payload) {
              // state.authenticated=true;
              state.CountryCode = payload;
          }
          else {
              //state.authenticated=false;
              state.CountryCode = 'AU';
          }

      },
  //getMATSOC
      [getMATSOC.pending]: (state, action) => {
          state.status = "loading";

      },
      [getMATSOC.fulfilled]: (state, { payload }) => {
     
      state.status = "success";
      
      if(payload){
       // state.authenticated=true;
          state.MATSOC = payload;
      }      
      else
      {
        //state.authenticated=false;
          state.MATSOC = {};
      }
      
    },
      [getMATSOC.rejected]: (state, action) => {
      state.status = "failed";
     // state.authenticated=false;
          state.MATSOC ={};
      console.log("Error from getRoles", action.error.message);
      },
      //getIndividualStatus
      [getIndividualStatus.pending]: (state, action) => {
          state.status = "loading";

      },
      [getIndividualStatus.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.IndividualStatus = payload;
          }
          else {
              //state.authenticated=false;
              state.IndividualStatus = [];
          }

      },
      [getIndividualStatus.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.ActivityStatus = [];
          console.log("Error from getRoles", action.error.message);
      },



      //getActivityStatus
      [getActivityStatus.pending]: (state, action) => {
          state.status = "loading";

      },
      [getActivityStatus.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.ActivityStatus = payload;
          }
          else {
              //state.authenticated=false;
              state.ActivityStatus = [];
          }

      },
      [getActivityStatus.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.ActivityStatus = [];
          console.log("Error from getRoles", action.error.message);
      },

      //getHCP_Type
      [getHCP_Type.pending]: (state, action) => {
          state.status = "loading";

      },
      [getHCP_Type.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCP_Type = payload;
          }
          else {
              //state.authenticated=false;
              state.HCP_Type = [];
          }

      },
      [getHCP_Type.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCP_Type = [];
          console.log("Error from getRoles", action.error.message);
      },


      //GetHCPSpecialty
      [GetHCPSpecialty.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCPSpecialty.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCPSpecialty = payload;
          }
          else {
              //state.authenticated=false;
              state.HCPSpecialty = [];
          }

      },
      [GetHCPSpecialty.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCPSpecialty = [];
          console.log("Error from getRoles", action.error.message);
      },



      //GetHCPPRIMARYAFFILIATION
      [GetHCPPRIMARYAFFILIATION.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCPPRIMARYAFFILIATION.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCPPRIMARYAFFILIATION = payload;
          }
          else {
              //state.authenticated=false;
              state.HCPPRIMARYAFFILIATION = [];
          }

      },
      [GetHCPPRIMARYAFFILIATION.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCPPRIMARYAFFILIATION = [];
          console.log("Error from getRoles", action.error.message);
      },


      //GetHCPTendency
      [GetHCPTendency.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCPTendency.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCPTendency = payload;
          }
          else {
              //state.authenticated=false;
              state.HCPTendency = [];
          }

      },
      [GetHCPTendency.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCPTendency = [];
          console.log("Error from getRoles", action.error.message);
      },

      //GetHCPRole
      [GetHCPRole.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCPRole.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCPRole = payload;
          }
          else {
              //state.authenticated=false;
              state.HCPRole = [];
          }

      },
      [GetHCPRole.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCPRole = [];
          console.log("Error from getRoles", action.error.message);
      },
      //GetHCOType
      [GetHCOType.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCOType.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCOType = payload;
          }
          else {
              //state.authenticated=false;
              state.HCOType = [];
          }

      },
      [GetHCOType.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCOType = [];
          console.log("Error from getRoles", action.error.message);
      },

      //GetHCOSpecialty
      [GetHCOSpeciality.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCOSpeciality.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCOSpeciality = payload;
          }
          else {
              //state.authenticated=false;
              state.HCOSpeciality = [];
          }

      },
      [GetHCOSpeciality.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCOSpeciality = [];
          console.log("Error from getRoles", action.error.message);
      },

      //HCOSector
      [GetHCOSector.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCOSector.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCOSector = payload;
          }
          else {
              //state.authenticated=false;
              state.HCOSector = [];
          }

      },
      [GetHCOSector.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCOSector = [];
          console.log("Error from getRoles", action.error.message);
      },

      //GetHCOMetroCountry
      [GetHCOMetroCountry.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCOMetroCountry.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.HCOMetroCountry = payload;
          }
          else {
              //state.authenticated=false;
              state.HCOMetroCountry = [];
          }

      },
      [GetHCOMetroCountry.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.HCOMetroCountry = [];
          console.log("Error from getRoles", action.error.message);
      },

      //
      //GetHCOState
      [GetHCOState.pending]: (state, action) => {
          state.status = "loading";

      },
      [GetHCOState.fulfilled]: (state, { payload }) => {

        state.status = "success";

        if (payload) {
            // state.authenticated=true;
            state.HCOState = payload;
        }
        else {
            //state.authenticated=false;
            state.HCOState = [];
        }

    },
      [GetHCOState.rejected]: (state, action) => {
        state.status = "failed";
        // state.authenticated=false;
          state.HCOState = [];
        console.log("Error from getRoles", action.error.message);
      },
      //RecordCount
      [request.pending]: (state, action) => {
          state.status = "loading";

      },
      [request.fulfilled]: (state, { payload }) => {

          state.status = "success";

          if (payload) {
              // state.authenticated=true;
              state.RecordCount = payload;
          }
          else {
              //state.authenticated=false;
              state.RecordCount = 0;
          }

      },
      [request.rejected]: (state, action) => {
          state.status = "failed";
          // state.authenticated=false;
          state.RecordCount = 0;
          console.log("Error from getRoles", action.error.message);
      },
      //report
      [report.pending]: (state, action) => {
          state.status = "loading";

      },
      [report.fulfilled]: (state, { payload }) => {

          state.status = "success";
          if (payload) {
              state.HCPRecords = payload;
              state.RecordCount = state.HCPRecords.length;
             // state.CountryCode = payload;
          }
          else {
              //state.authenticated=false;
              //state.RecordCount = 0;
          }

      },
      //[userType.fulfilled]: (state, action) => {
      //    state.status = "success";

      //    if (payload) {
      //        // state.authenticated=true;
      //        state.CountryCode = payload;
      //    }
      //    else {
      //        //state.authenticated=false;
      //        //state.RecordCount = 0;
      //    }

      //},
  },
});
export const { endUserSession, updatecountry, updateuserTypet, updateRecordCount, updateMATSOC, updateIndividualStatus, updatActivityStatus, updateHCOSector, updateHCOSpeciality, updateHCOState, updateHCOType, updateHCPPRIMARYAFFILIATION, updateHCPRole, updateHCPSpecialty, updateHCPTendency, updatHCPType, UpdateHCOMetroCountry, updateparama } = RequestSlice.actions;
export default RequestSlice.reducer;
